/* .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-topContainer .MuiDataGrid-columnHeaders .css-1essi2g-MuiDataGrid-columnHeaderRow {
  background-color: #1F2026;
} */

/* Works on Chrome, Edge, and Safari */
@font-face {
  font-family: "Inter";
  /*Can be any text*/
  src: local("Inter-Black"),
    url("./font/static/Inter-Black.ttf") format("truetype");
}


*::-webkit-scrollbar {
  width: 10px;
  /* height: 100%; */
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: 10px;
}

:root {
  --light-background-color: #ffffff;
  --dark-background-color: #1F2026;
  --dark-border-color: 1px solid rgb(64, 68, 79);
  --border-color: rgb(64, 68, 79);
}

body .MuiDataGrid-root,
body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  font-family: 'Inter var', -apple-system, BlinkMacSystemFont, sans-serif;
}

body[data-theme='dark'] {
  background-color: #23242A;
}

body[data-theme="light"] .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-topContainer .MuiDataGrid-columnHeaders div[role="row"] {
  background-color: var(--light-background-color);
}

body[data-theme="dark"] .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-topContainer .MuiDataGrid-columnHeaders div[role="row"] {
  background-color: var(--dark-background-color);
}


body[data-theme="dark"] .MuiToolbar-gutters {
  color: var(--light-background-color);
}

body[data-theme="dark"] .MuiInputBase-colorPrimary .MuiSvgIcon-fontSizeMedium {
  color: var(--light-background-color);
}

body[data-theme="dark"] .MuiDataGrid-root.MuiDataGrid-root--densityStandard {
  border: var(--dark-border-color);
}

body[data-theme="dark"] .MuiDataGrid-topContainer::after {
  background-color: rgba(43, 43, 43, 0.435) !important;
}

body[data-theme="dark"] select#accountPosition option {
  background-color: var(--dark-background-color);
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  display: none;
}

body[data-theme="dark"] .MuiDataGrid-iconButtonContainer .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  color: var(--light-background-color);
  display: block;
}

body[data-theme="light"] .MuiDataGrid-iconButtonContainer .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  display: block;
}

body[data-theme="dark"] .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
  border-color: rgba(43, 43, 43, 0.435) !important;
  justify-content: center;
}

body[data-theme="light"] .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
  justify-content: center;
}

/* body[data-theme="dark"] .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  border-color: var(--border-color);
} */

.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-topContainer .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row.MuiDataGrid-row--firstVisible .MuiDataGrid-cellOffsetLeft .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  width: 0 !important;
}

body[data-theme="dark"] .MuiTablePagination-actions .MuiButtonBase-root.Mui-disabled {
  color: gray;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader--sorted.MuiDataGrid-withBorderColor {
  outline: none;
}

body[data-theme="dark"] .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell {
  border-top: 1px solid rgba(43, 43, 43, 0.435) !important
}

body .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

body .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

body .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  padding-left: 10px !important;
}

.Mui-focused {
  outline: none;
}

.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-menuIcon.MuiDataGrid-menuOpen {
  display: none !important;
}

.MuiInputBase-root-MuiOutlinedInput-root {
  outline: none;
}

/* .css-dqg0n3-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 12px 4px 7.5px 5px !important;
} */


/* @media (max-width: 1075px) {
  .tokenChartHeight {
    height: 22.5rem;
  }
}

@media (max-width: 1023px) {
  .tokenChartHeight {
    height: 21rem;
  }
} */

body .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 10px 4px;
  font-size: 14px;
}

.search-list-section {
  background-color: transparent;
  font-size: 14px;
  margin-top: 24px;
}

.search-list-item {
  font-size: 14px;
}

body .MuiPaper-root .MuiAutocomplete-listbox {
  max-height: 60vh;
}

body .MuiList-root.MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  border-radius: 8px;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  font-size: 14px;
}

body[data-theme="dark"] .account-position .MuiOutlinedInput-notchedOutline,
body[data-theme="dark"] .account-position .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline,
body[data-theme="dark"] .account-position .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--light-background-color);
}

body[data-theme="light"] .account-position .MuiOutlinedInput-notchedOutline,
body[data-theme="light"] .account-position .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline,
body[data-theme="light"] .account-position .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #e5e7eb;
}

.account-position .MuiOutlinedInput-notchedOutline {
  border-radius: 8px;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl .MuiSelect-select.MuiInputBase-input {
  padding-top: 12px;
  padding-bottom: 12px;
}

body[data-theme="dark"] .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  background-color: #2C2F36;
}

body[data-theme="light"] .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  background-color: #F7F8FA;
}

body[data-theme="light"] .search-dropdown .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid gray;
}

@media (max-width: 1083px) {
  .pairChartFirstButton {
    margin-top: 5px;
    position: relative;
    left: -7px;
  }
}

@media (max-width: 1023px) {
  .pairChartFirstButton {
    margin-top: 0;
    position: relative;
    left: 1px;
  }
}

@media (max-width: 448px) {
  .pairChartFirstButton {
    margin-top: 8px;
    position: relative;
    left: -8px;
  }
}

@media (max-width: 327px) {
  .pairChartFirstButton {
    margin-top: 8px;
    position: relative;
    left: -9px;
  }
}

@media (max-width: 1083px) {
  .pairChartSecondButton {
    position: relative;
    left: -7px;
  }
}

@media (max-width: 1023px) {
  .pairChartSecondButton {
    position: relative;
    left: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1083px) {
  .pairChartSecondButton {
    margin-top: 5px;
  }
}

@media (max-width: 327px) {
  .pairChartThirdButton {
    margin-top: 8px;
    position: relative;
  }
}

/* Media queries for different screen sizes */

@media (min-width: 1024px) {
  body .MuiDataGrid-cell {
    width: calc(100% / 8);
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .MuiDataGrid-cell {
    width: calc(100% / 6);
  }
}

@media (max-width: 599px) {
  .MuiDataGrid-cell {
    width: calc(100% / 4);
  }
}

@media (max-width: 425px) {
  body .MuiDataGrid-cell {
    width: calc(100% / 4);
  }
}